.containerPopup{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    z-index: 1;
    background-color: aliceblue;
    padding: 1em 2em;
    border-radius: 10px;
    
}
.containerPopup h2{
    margin-bottom: 20px;
}
.containerPopup p{
    margin-bottom: 15px;
}
.acceptBtn{
    padding: 10px 20px;
    background-color: #000000;
    border-radius: 10%;
    color: #fff;
}