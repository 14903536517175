.container {
  min-height: 100vh;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #e6f4ff 0%, #b3e0ff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: system-ui, -apple-system, sans-serif;
  width: 100%;
}

.content {
  width: 100%;
  margin: 0 auto;
}

.title {
  color: #0a2472;
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}

.subtitle {
  color: #1e56b0;
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 4rem;
}

.steps {
  display: flex;
  padding: 0 5rem;
  justify-content: space-between;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.step {
  position: relative;
  flex: 1;
  min-width: 280px;
  max-width: 350px;
}

.stepNumber {
  width: 3.5rem;
  height: 3.5rem;
  background: #0a2472;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  top: -1.75rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  height: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card h2 {
  color: #0a2472;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.uploadIcon {
  position: relative;
  width: 80px;
  height: 80px;
}

.imggenerateIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.generateText{
  position: absolute;
  color: #ffffff !important;
  top:10px
}
.imggenerateIcon img{
  height: 3em;
  width: 10em;
}
.imgcloudIcon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgcloudIcon img{
  position: absolute;
  top: -40px;
  height: 10em;
  width: 10em;
}
.uploadLine {
  width: 40%;
  height: 3px;
  background: #4285f4;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.radioGroup {
  background: #f5f7ff;
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
}

.radioOption {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.radioLabel {
  color: #666;
  font-size: 0.875rem;
}

.radioButtons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.radio {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}

.radio input {
  margin: 0;
}

.radio span {
  font-size: 0.875rem;
  color: #333;
}

.generateButton {
  background: #4285f4;
  color: white;
  border: none;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.generateButton:hover {
  background: #3367d6;
}

.checkButton {
  background: #0a2472;
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.checkButton:hover {
  background: #081b5c;
}

@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .steps {
    flex-direction: column;
    align-items: center;
  }
  
  .step {
    width: 100%;
  }
}

