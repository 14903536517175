/* FileUpload.css */

.file-upload {
    max-width: 750px;
    margin: 0 auto;
    position: fixed;
    top: 15%;
    left: 25%;
    width: 60%;
    padding: 2em 2em;
    z-index: 5;
    padding-right: 4em;
    border-radius: 12px;
    border: 1px solid #e1e1e1;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
    background-color: #f7f7f7;
    font-family: Arial, sans-serif;
}
.file-upload-box{
    
    width: 100%;
    max-height: 25em;
    overflow-y: auto;
    overflow-x: hidden; 
}
.file-upload-box3{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1em 1em;
    color: #373737;
    margin-bottom: 3em;
    border: 2px solid #3a69ea;
    border-radius: 8px;
}

.file-upload h2 {
    /* text-align: center; */
    color: #545454;
    font-size: 2em;
    margin-bottom: 20px;
    font-weight: 400;
}

.file-upload input[type="file"] {
    display: block;
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #007bff;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-size: 1em;
}

.file-upload ul {
    list-style-type: none;
    padding: 0;
}

.file-upload li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    margin: 10px 0;
    background-color: #f7f7f7;
    transition: background-color 0.3s;
}

.file-upload li:hover {
    background-color: #f1f1f1;
}

.file-upload button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, transform 0.2s;
}

.file-upload button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.remove-btn {
    background-color: #f4f4f4;
}
.file-names-selectfile{
    background-color: #0f74e0;
    color: #ffffff;
    padding: 8px 17px;
    font-weight: bold;
    font-size: 17px;
    border-radius: 10px;
}

.remove-btn:hover {
    background-color: #ffffff;
}

.file-names {
    max-height: 25em; /* Set a max-height to enable scrolling */
    overflow-y: auto;  /* Enables vertical scroll */
}
.masterListButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .masterListButton:hover {
    background-color: #0056b3;
  }
  
  .container-modal{
    background-color: #fff;
    width: 300px;
    border-radius: 10px;
    top:90%;
    height: 250px;
    right: -32%;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    overflow-y: auto;
  }
  
  .modalTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .closeButton {
    position: absolute;
    top: -10px;
    right: -1px;
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: #333;
  }
  
  .topicList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .topicItem {
    cursor: default;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .topicItem:last-child {
    border-bottom: none;
  }
  .selected {
    padding: 8px;
    background-color: #545454 !important;
    color: #ddd;
  }
  
  .selected:last-child {
    border-bottom: none;
  }
  