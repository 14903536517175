.leftSideMain {
  width: 100%; 
  height: auto;                   
  padding: 20px;                
  background-color: #ffffff;      
  border: 1px solid #ddd;         
  border-radius: 5px;             
  box-sizing: border-box;         
}

.content {
  font-size: 18px;                
  font-weight: bold;             
  margin-bottom: 10px;           
}

.allLinks {
  display: flex;                 
  flex-direction: column;       
  /* gap: 8px;   */
}

.contenthead a {
  margin: 10px;                   
  display: block;               
  font-size: 14px;              
  color: #242424;                    
  cursor: pointer;               
  transition: color 0.3s ease;   
  font-weight: bold;
  font-size: 17px;
}

.contenthead a:hover {
  color: #525252;
  text-decoration: none;                 
}

.squareImage {
  width: auto;
  height: auto;
}
.squareImage img{
  width: 70%;
  height: 80%;
  justify-items: center;
  align-items: center;
}

.contenthead span {
  display: block;
  padding: 8px 0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; 
}

.contenthead span:hover {
  color: black;
  font-weight: bold; 
}

.contenthead .active {
  font-weight: bold; 
  color: black;
  
}
.contenthead span.active {
  font-weight: bold;
  color: black;
}

/* @media (min-width: 360px) and (max-width: 740px) {
  
}
 */


