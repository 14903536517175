

.custom-container h1 {
    font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }


  .custom-container h2 {
    font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }

  .custom-container h3 {
    font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }

  .custom-container h4 {
    font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }
  
  .custom-container h5 {
    font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  padding: 5px;
  }

  .custom-container p {
    margin-top: 10px;
    width: 100%;
    padding: 5px;
  }

  .custom-container strong {
    font-weight: bold;
  }

  .custom-container li {
    padding: 5px;
    list-style-type: disc;
  }
  .generateContainer{
    width: 12em;
    height: 5em;
    background: url('../../../assets/generateIcon-cropped.svg') no-repeat center center;
    background-size: contain;
    position: absolute;
    left:280px;
    top:-28px;
  }