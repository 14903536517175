.container {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.title {
  font-size: 2.5rem;
  color: #4169e1;
  margin-bottom: 0.5rem;
  padding-top: 2em;
}

.subtitle {
  color: #666;
  margin-bottom: 2rem;
}

.paymentMethods {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.methodCard {
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.methodHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.cardIcon {
  margin-right: 0.5rem;
}

.cardLogos {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.visaLogo, .amexLogo {
  font-family: sans-serif;
  font-weight: bold;
}

.more {
  color: #666;
  font-size: 0.9rem;
}

.cardInputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fullWidth {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.cardDetails {
  display: flex;
  gap: 1rem;
}

.halfWidth {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.methodOption {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  border-top: 1px solid #eee;
}

.methodOption input[type="radio"] {
  margin-right: 1rem;
}

.methodOption label {
  flex: 1;
}

.paypalLogo, .stripeLogo {
  font-family: sans-serif;
  font-weight: bold;
}

.remember {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
}

.remember label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggle {
  width: 40px;
  height: 24px;
  background: #ddd;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}

.toggle:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: 0.2s;
}

.remember input[type="checkbox"] {
  display: none;
}

.remember input[type="checkbox"]:checked + .toggle {
  background: #4169e1;
}

.remember input[type="checkbox"]:checked + .toggle:after {
  transform: translateX(16px);
}

.phoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
}

.phoneIcon {
  margin-right: 0.5rem;
}

.phoneInput input {
  border: none;
  outline: none;
  width: 100%;
}

.subscribeButton {
  width: 100%;
  padding: 1rem;
  background: #4169e1;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subscribeButton:hover {
  background: #3158d3;
}

