.container {
    width: fit-content;
    padding: 0 14em;
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    text-justify: inter-word;
  }
  .headContain{
    font-weight: bold;
    font-size: 25px;
    margin-top: 1em;
    color: #3a3a3a;
  }

  .signUpOffer{
    cursor: pointer;
  }
  
  .heroContent {
    padding-top: 8rem; /* pt-32 */
    padding-bottom: 2.5rem; /* pb-10 */
  }
  
  @media (min-width: 768px) {
    .heroContent {
      padding-top: 5rem; /* md:pt-40 */
      padding-bottom: 4rem; /* md:pb-16 */
    }
  }
  
  .header {
    max-width: 48rem; /* max-w-3xl */
    margin-left: auto;
    margin-right: auto;
    /* text-align: center; */
    padding-bottom: 3rem; /* pb-12 */
  }
  
  @media (min-width: 768px) {
    .header {
      padding-bottom: 4rem; /* md:pb-16 */
    }
  }
  
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem; /* mb-2 */
  }
  
  .svgLogo {
    fill: grey;
  }
  
  .svgLogoDark {
    fill: white;
  }
  
  .iconWrapper {
    width: 5rem; /* w-16 */
    height: 5rem; /* h-16 */
    margin-bottom: 1rem; /* mb-4 */
    border-radius: 20%; /* rounded-full */
    background-color: rgb(211, 255, 253);
    position: relative;
    border:2px solid #5890ff;
    
  }
  
  .icon {
    position: absolute;
    top: 1.5rem; /* top-5 */
    left: 1.5rem; /* left-6 */
    color: white;
  }
  
  .iconSecond {
    left: 1.25rem; /* left-[18px] */
  }
  .title {
    margin-bottom: 0.5rem; /* mb-2 */
  }
  
  .text {
    color: #6b7280; /* text-gray-500 */
    /* text-align: center; */
  }
  
  .dark .text {
    color: #d1d5db; /* text-gray-300 */
  }
  .customContainer {
    max-width: 100%; /* max-w-sm */
    margin-left: auto; /* mx-auto */
    margin-right: auto; /* mx-auto */
    display: grid; /* grid */
    gap: 2rem; /* gap-8 */
    grid-template-columns: repeat(3, 1fr); 
    /* Other default styles */
  }
  
  @media (max-width: 968px) { /* md breakpoint */
    .customContainer {
      grid-template-columns: repeat(2, 1fr); /* md:grid-cols-2 */
      max-width: 42rem; /* md:max-w-2xl */
    }
  }
  .customSubContainer {
    height: 100%;
    /* border: 1px solid #000; */
    border-radius: 1em;
    padding: 1em 2em;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
    position: relative; /* relative */
    display: flex; /* flex */
    flex-direction: column; /* flex-col */
    align-items: center; /* items-center */
  }
  .customText {
    font-size: 1.125rem; /* text-lg */
    color: #6b7280; /* text-gray-500 */
    /* text-align: center;  */
  }
  .customHeading {
    font-size: 1.5rem; /* h4 equivalent */
    margin-bottom: 1rem; /* mb-2 equivalent */
    font-weight: bolder;
  }
  .targetIcon{
    position: absolute;
    top: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    background: url('../../assets/targetIcon.svg') no-repeat center center;
    background-size: contain;
  }
  .targetIcon2{
    position: absolute;
    top: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    background: url('../../assets/copyItemicon.svg') no-repeat center center;
    background-size: contain;
  }
  .targetIcon3{
    position: absolute;
    top: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    background: url('../../assets/settingFeatureIcon.svg') no-repeat center center;
    background-size: contain;
  }
  .anouncementContainer{
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 20px;
    width: 100%;
    background-color: rgb(66, 137, 245);
  }