.container {
  background-color: #1a1a1a;
  border-radius: 18px;
  position: absolute;
  left:74%;
  top:85%;
  height: 990%;
  width: 25%;
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.header {
  background-color: #563d7c;
  padding: 20px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1rem
}

.headerTitle {
  font-size: 1.3rem;
  margin: 0;
}

.avatar {
  width: 70px;
  height: 70px;
  background-color: #8b7355;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin: 0.5rem auto;
}

.profileInfo {
  text-align: center;
  padding: 0.5rem;
}

.name {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.email {
  color: #cccccc;
  margin: 0.5rem 0;
}

.iconContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1.5rem 0;
}

.icon {
  width: 40px;
  height: 40px;
  background-color: #563d7c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.syncCard {
  background-color: #2d2d2d;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
}

.syncTitle {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.syncButton {
  width: 100%;
  padding: 1rem;
  background-color: #e6d5f5;
  border: none;
  border-radius: 25px;
  color: #1a1a1a;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.syncButton:hover {
  background-color: #d4b8f9;
}

.menuList {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-right: 19px;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menuItem:hover {
  background-color: #2d2d2d;
}

.menuIcon {
  margin-right: 1rem;
  width: 20px;
  height: 20px;
}

.progressItem{
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}