/* styles.module.css */

.container {
  display: flex;
  align-items: center;
}

.timeUnit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeValue {
  display: flex;
  align-items: center;
}

.timeValue span {
  margin-left: 5px;
}

.progressBar {
  width: 5em;
  margin-right: 8px;
}

.progressBar .MuiLinearProgress-bar {
  height: 10px;
  border-radius: 5px;
  background-color: #4caf50;
}

.tooltip {
  display: none;
  position: absolute;
  bottom: -4em;
  left: 50%;
  transform: translateX(-10%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.container:hover .tooltip {
  display: block;
}