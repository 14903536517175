.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
}

.content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    flex-direction: column;
}

.FooterFloat{
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 1000;
    height: fit-content;
}

.NavbarandBody{
    padding-bottom: 5rem;
}