.sectionContainer {
  /* padding-top: 4em; */
    /* max-width: 6xl; */
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0rem 7rem 5rem 7rem;
    background-color: #ffffff;
    height: fit-content;
  }
  .sectionPadding {
    /* padding-top: 3rem;
    padding-bottom: 3rem; */
  }
  .titleBlogs{
    padding-left: 7%;
    font-size: 4em;
  }
  .blogSection{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3em;
    padding: 0 15em;
  }
  .contentBlogImg h3{
    font-weight: bold;
    font-size: 30px;
    padding-left: 1em;
    padding-top: 7em;
    color:#fff;
  }

  .contentBlog{
    height: 40rem;
    border: 1px solid #cfcfcf;
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    cursor: pointer;
  }
  .contentBlogImg {
    width: 100%;
    /* height: 17em; */
    height: 16em;
    background: 
      linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), 
      url('../../../assets/build_vs_buy_blog.png') no-repeat center center;
    background-size: contain;
}
  .contentBlogText{
    overflow: hidden;
  }
  .headTextBlog{
    font-weight: bold;
    font-size: 25px;
    padding:0 20px;
    padding-bottom: 10px;
    padding-left: 25px;
    overflow: hidden;
    /* white-space: nowrap;  */
    text-overflow: ellipsis;
  }
  .paraTextBlog{
    font-size: 18px;
    padding: 0 20px;
    padding-left: 25px;
    color: #7f7f7f;
    padding-bottom: 1em;
    overflow: hidden;
    /* white-space: nowrap;  */
    text-overflow: ellipsis;
  }

  .moreButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
  .moreButton, .moreButton:hover{

    background-color: rgb(26, 106, 226);
    color: #fff;

    text-decoration: none;
    
    
    border-radius: 2px;
    width: fit-content;
    height: fit-content;
    font-weight: 200;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    padding: 1rem;
    /* border: #00000085 solid 1px; */
  }

  /* .moreButton:hover{
    text-decoration: none;
  } */
  
  @media (min-width: 640px) {
    .sectionContainer {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  
  @media (min-width: 768px) {
    .sectionPadding {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
  
  
  @media (min-width: 768px) {
  }
  
  @media (prefers-color-scheme: dark) {
  }