.banner {
  text-align: center;
  margin-top: 4em;
}

.bannerImage {
  width: 100%;
  height: 45vh;
  background-color: rgb(15, 91, 206);
  margin-bottom: 50px;
  position: relative; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.bannerImage h1 {
  font-size: 55px;
  margin: 5px;
  font-family: sans-serif;
}

.date {
  top: 10px;
  color: white;
  font-size: 15px;
  font-family: sans-serif;
}


@media (min-width: 360px) and (max-width: 740px) {
  .bannerImage {
    height: 30vh; 
    width: 100%;
  }
  
  .bannerImage h1 {
    font-size: 20px; 
  }
  
  .date {
    font-size: 12px; 
  }
}
