
.headLine{
    margin-top: 2rem;
    padding:5% 0;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 36px;
    color: #333;
    position: relative;
}
.headLine::after{
    position: absolute;
    content: "";
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    height: 4px;
    width: 60px;
    bottom: 2.5rem;
    margin-left: -30px;
    left: 50%;
}
.blogSection{
    display: flex;
    flex-direction: row;
    gap: 3em;
}
.contentContainer{
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: center;
    padding: 3em 9px;
}
.imageStyles img{
    border-radius: 50%;
}
.textTestimonial{
    overflow: hidden;
    
}
.textTestimonial p{
    padding-bottom: 4px;
    margin-bottom: 0;
    font-size: 14px;
    font-style: italic;
    color: #646464;
}
.testMeta{
    margin-bottom: 10px; 
    font-size: 15px;
    font-weight: 600;
    color: #666;
}
.container{
    padding: 0 15rem;
}