.pulsing {
    animation: pulse-animation 1s ease-in-out infinite;
}

@keyframes pulse-animation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2); /* Adjust this to control the size increase */
    }
}