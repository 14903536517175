.rightMain {
  text-align: justify;
  /* background-color: rgb(255, 255, 255); */
  color: rgb(0, 0, 0);
  padding: 10px;
  width: 100%; 
  position: relative; 
}

.upperDes {
  position: relative;
  text-align: justify;
  font-family: sans-serif;
  padding: 10px;
  line-height: 1.667em;
  font-size: 24;
  font-family: sans-serif;
}
.heading {
  color: black;
  font-size: 29px;
  font-family: sans-serif;
  font-weight: 600;
  margin: 10px;
  width: 700px;
  text-align: justify;
  text-align: left;
  position: relative;
}

.description {
  position: relative;
  text-align: justify;
  font-family: sans-serif;
  padding: 10px;
  font-size: 18px;
  line-height: 1.667em;
}
.clas2{
  padding-top: 80px;
  margin-top: -80px; 
}

.des {
  font-size: 18px;
  margin: 10px;
  width: 700px;
  line-height: 1.667em;
  font-family: sans-serif;
}

@media (min-width: 360px) and (max-width: 740px) {
  .heading {
    font-size: 20px;
    text-align: justify;
    width: 100%;
    
  }
}

