.form {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 1rem;
}

.button {
  padding: 0.5rem 1rem;
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.button:hover {
  background-color: #40a9ff;
}

