
.sectionContainer{
    flex: 1;
    width: 100%;
    /* margin: 0 10%; */
}
.subHeading{
    width: 70%;
    font-size: 3em;
    font-weight: bold;
    margin: 0 auto; 
    float: left;
}
.tryButton{
    cursor:default;
    margin-top: 3em;
    border-radius: 5px;
    background-color: rgb(26, 106, 226);
    color: #fff;
    padding: 7px 12px;
    width: 50%;
    height: 54px;
    font-weight: 200;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 22px;
  }

  .tryButton a{
    text-decoration: none;
    color: #fff;
  }
.subPara{
    width: 70%;
    margin: 0 auto;
    margin-top: 2em;
    color: rgb(97, 97, 97);
}
.secondHead{
    color: rgb(123, 0, 255);
}