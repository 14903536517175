.questionList {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .questionItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .questionItem:last-child {
    border-bottom: none;
  }
  
  .deleteButton {
    padding: 0.25rem 0.5rem;
    background-color: #ff4d4f;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .deleteButton:hover {
    background-color: #ff7875;
  }
  .actionButton {
    padding: 0.25rem 0.5rem;
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }
  
  .actionButton:hover {
    background-color: #f0f0f0;
  }
  .topicActions {
    display: flex;
    gap: 0.5rem;
  }