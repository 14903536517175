/* Allpagee.css */

.Home {
  /* background-color: green; */
  /* overflow: hidden;  */
  position: relative;
}

.all-page-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* height: 100vh;  */
  padding: 10px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  height: 100%;
}

.leftside {
  position: sticky; 
  top: 6%; 
  width: 40%; 
  /* background-color: #002fd7; */
  /* z-index: 1;  */
  padding: 10px;
  /* height: calc(100vh - 10px); */
}

.rightside {
  width: 60%;
  /* background-color: #c10707; */
  padding: 10px;
  /* height: calc(100vh - 10px);  */
  /* overflow-y: scroll; */
  max-height: auto;
}

@media (min-width: 360px) and (max-width: 740px) {
  
  .leftside {
    display: none !important;;
  }
  .rightside {
    width: 100%;
  }
  
}

