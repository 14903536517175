
.footer {
    background-color: rgb(16, 94, 212);
    padding: 20px;
    text-align: center;
    justify-content: space-between;
    padding-right:9em;
    width: 100%;
    height: 5em;
    color: aliceblue;
    display: flex;
    align-items: center;
}
.mainLogoIcon {
    width: 14em;
    height: 3em;
    background: url('../../assets/mainLogo2.svg') no-repeat center center;
    background-size: contain;
    filter: invert(100%) brightness(100%) saturate(100%);
  }
  .besideDetails{
    display: flex;
    align-items: center;
    gap: 2em;
  }

/* @media (min-height: 800px) {
    .footer {
        
    }
} */
