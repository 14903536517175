.container {
  min-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 4rem;
  top:10%;
  position: absolute;
  z-index: 3;
  
}
.uploadBox{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:2em;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 70vh;
  overflow-y: auto;  /* Enable vertical scrolling */
  overflow-x: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.cardHeader {
  padding: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.cardContent {
  padding: 1.5rem;
}



.button {
  padding: 0.5rem 3rem;
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 22px;
}

.button:hover {
  background-color: #40a9ff;
}

