.boxContent{
    background-color: #fff;
    border-radius: 3%;
    width: 100%;
    height: 25em;
    border: 2px solid #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.fileLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.fileLabel span{
    font-size: 15px;
    font-weight: bold;
    color: #000;
}
.uploadIcon2 {
    width: 8em;
    height: 8em;
    background: url('../../../assets/upload-cloud-icon.svg') no-repeat center center;
    background-size: contain;

}
.textFile{
    top: 203px;
    position: absolute; 
    font-size: 13px !important;
    color: #fff !important;
}
.fileInput {
    display: none;
}
.Box{
    float: right;
    width: fit-content;
}
.boxContent {
    background-color: #fff;
    border-radius: 3%;
    width: 100%;
    height: 25em;
    border: 2px solid #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.mathUploadContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fileLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.fileLabel span {
    font-size: 15px;
    font-weight: bold;
    color: #000;
}

.textFile {
    top: 203px;
    position: absolute;
    font-size: 13px !important;
    color: #fff !important;
}

.fileInput {
    display: none;
}
.Errorandword{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.wordCount{
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

h2 {
    font-size: 1.5em;
    margin: 0.75em 0;
}

ul {
    list-style-type: disc;
    
    padding-left: 20px;
    
    margin: 1em 0;
    
}


li {
    margin-bottom: 10px;
    
}
.steps {
  display: flex;
  padding-top: 2em;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  gap: 2em;
}
.uploadIcon2 {
    position: relative;
    width: 80px;
    height: 80px;
    bottom: -2em;
}

.step {
  position: relative;
  flex: 1;
  /* min-width: 280px; */
  max-width: 350px;
}

.stepNumber {
  width: 2rem;
  height: 2rem;
  background: #0a2472;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  top: -1.75rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.card {
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  height: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card h2 {
  color: #0a2472;
  font-size: 1.2rem;
  /* margin-bottom: 1rem; */
  font-weight: 600;
}

.card p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.uploadIcon {
  position: relative;
  width: 124px;
  height: 124px;
  background: url('../../../assets/upload-cloud-icon.svg') no-repeat center center;
  background-size: contain;

}

.imggenerateIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.generateText{
  position: absolute;
  color: #ffffff !important;
  top: 9.5em;
  right: 7em;
}
.imggenerateIcon img{
  height: 1.7em;
  width: 14em;
}
.imgcloudIcon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgcloudIcon img{
  position: absolute;
  top: -40px;
  height: 10em;
  width: 10em;
}
.uploadLine {
  width: 40%;
  height: 3px;
  background: #4285f4;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.radioGroup {
  background: #f5f7ff;
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 100%;
}

.radioOption {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.radioLabel {
  color: #666;
  font-size: 0.875rem;
}

.radioButtons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.radio {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
}

.radio input {
  margin: 0;
}

.radio span {
  font-size: 0.875rem;
  color: #333;
}

.generateButton {
  background: #4285f4;
  color: white;
  border: none;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.generateButton:hover {
  background: #3367d6;
}


@media (max-width: 768px) {
  .title {
    font-size: 2.5rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .steps {
    flex-direction: column;
    align-items: center;
  }
  
  .step {
    width: 100%;
  }
}

