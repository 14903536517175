.appbarContainer{
  background-color: #fff !important;
  color: #000 !important;
  width: 100% !important;
  position: relative;
}
.appbarSub{
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 4em;
}
.tryButton{
  border-radius: 5px;
  border: none;
  background-color: rgb(20, 114, 255);
  color: #fff;
  padding: 7px 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: medium;
  width: 100%;
}
.tryButton :hover{
  color:#fff;
  text-decoration: none;
  
}
@media (min-width: 1200px) {
  .appbarSub {
      max-width: 100% !important;
  }
}
.navbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:0 1px;
    font-size: 16px;
    font-weight: 500;
    /* margin-left: 10%; */
  }
  
  .navLink {
    color: rgb(99, 99, 99);
    text-decoration: none;
    margin: 0 15px;
    padding: 8px 6px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .logout {
    cursor:pointer;
    color: rgb(99, 99, 99);
    text-decoration: none;
    margin: 0 15px;
    padding: 8px 6px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  .navLink:hover {
    color: rgb(99, 99, 99);
    text-decoration: none;
  }
  .subBtnGetstarted{
    display: flex;
    gap: 3rem;
    align-items: center;
  }
  .mainLogoIcon {
    /* top: 15px; */
    position: relative;
    width: 10vw;
    height: 40px;
    background: url('../../assets/mainLogo.svg') no-repeat center center;
    background-size: contain;
    /* margin-bottom: 10px; */
  }
  
  .Userimage{
    /* width: 15vw; */
    display: flex;
    align-content: center;
    width: 10%;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 768px) {
    .navbarContainer {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .navLink {
      margin: 5px 0;
    }
  }
  .fileLabel {
    cursor: pointer;
  }
  .fileInput {
    display: none;
  }
  .sumBtn{
    background-color: #212121;
    color: #e0e0e0;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
  }
  .sumBtn:hover{
    background-color: #000;
    color: #fff;
    cursor: default;
  }
  .avatarImg{
    position: absolute;
    right: 2%;
  }