.loginContainer {
  position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to right, #f6f3ff, #d7c9ff);
  }
  
  .loginBox {
    background: #fff;
    padding: 40px 60px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
  
  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
  }
  
  .subtitle {
    font-size: 16px;
    margin-bottom: 30px;
    color: #666;
  }
  
  .googleButton {
    width: 100%;
    background-color: #4285F4 !important;
    color: white !important;
    border: none !important;
    font-size: 16px !important;
    padding: 10px 0 !important;
    border-radius: 5px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .googleButton:hover {
    background-color: #357ae8 !important;
  }
  
  .form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333333;
}

.inputGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555555;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50 !important;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #009608 !important;
}

.error {
  top: 21em;
  margin-top: 10px;
  color: red;
  text-align: center;
  font-size: 14px;
  position: absolute;
}