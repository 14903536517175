.Main{
    display: flex;
    height: 90vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}
.Text{
    font-size: 40px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.475);
}