.modalOverlay {
  position: fixed;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  
}
.featuresContainIcon{
  display: flex;
  align-items: center;
  gap: 5px;
}
.modal {
  background: white;
  border-radius: 12px;
  padding: 1rem;
  position: relative;
  width: 90%;
  /* margin-top: 5rem; */
  height: 98vh;
  max-width: 900px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.closeButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.modalContent {
  text-align: center;
}

.subtitle {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.description {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.billingToggle {
  display: inline-flex;
  background: #f5f5f5;
  border-radius: 25px;
  padding: 0.25rem;
  margin-bottom: 2rem;
}

.toggleButton {
  padding: 0.5rem 1.5rem;
  border: none;
  background: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
}

.toggleButton.active {
  background: #4169E1;
  color: white;
}

.plansContainer {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-bottom: 2rem;
}

.planCard {
  max-width: 300px;
  padding: 2rem;
  border: 1px solid #eee;
  border-radius: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 65vh; 
}

.planCard.highlighted {
  border: 2px solid #4169E1;
}

.planName {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.planSubtitle {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.featuresList {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.featuresList li {
  margin-bottom: 0.75rem;
  color: #333;
}

.pricing {
  margin-bottom: 1.5rem;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
}

.period {
  color: #666;
  font-size: 0.9rem;
}

.continueButton,
.subscribeButton,
.bottomContinueButton {
  align-self: flex-end;
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  border: 1px solid #eee;
  background: white;
  cursor: pointer;
  font-size: 0.9rem;
}

.subscribeButton {
  background: #4169E1;
  color: white;
  border: none;
}

.bottomContinueButton {
  max-width: 300px;
  margin: 0 auto;
  background-color: yellow;
}

@media (max-width: 768px) {
  .plansContainer {
    flex-direction: column;
    align-items: center;
  }

  .planCard {
    width: 100%;
  }
}

