.topicList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topicCard {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.topicHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
}

.topicName {
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
}

.topicActions {
  display: flex;
  gap: 0.5rem;
}

.actionButton {
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
}

.actionButton:hover {
  background-color: #f0f0f0;
}

.topicContent {
  padding: 1rem;
}