.container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f0f8ff 0%, #87ceeb 100%);
  padding: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.title {
  font-size: 3rem;
  color: #0a2472;
  margin-bottom: 1rem;
  font-weight: bold;
}

.subtitle {
  color: #4169e1;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 3rem;
}

.fileSection {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.fileCard {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
}

.fileLabel {
  color: #0a2472;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.fileIcon {
  background: #e8f0fe;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 0.5rem;
}

.icon {
  width: 40px;
  height: 40px;
  color: #4169e1;
}

.fileName {
  color: #4169e1;
  font-size: 0.9rem;
}

.questionCard {
  background: white;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
  width: 100%;
  max-width: 400px;
}

.question {
  color: #4169e1;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.radioGroup {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.radioLabel {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  cursor: pointer;
}

.radioInput {
  margin-right: 0.5rem;
}

.radioText {
  color: #666;
}

.submitButton {
  background: #0a2472;
  color: white;
  padding: 0.8rem 2rem;
  border-radius: 8px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background: #083799;
}

.arrow {
  font-size: 1.2rem;
}

@media (max-width: 640px) {
  .title {
    font-size: 2rem;
  }
  
  .subtitle {
    font-size: 1rem;
  }
  
  .fileSection {
    gap: 1rem;
  }
  
  .fileCard {
    min-width: 160px;
  }
}
.uploadCard{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

.mainContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  height: 100%;
}
.textContainer{
  display: flex;
  height: 9%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.sumaryText{
  font-size: 34px;
  font-weight: bolder;
  color: rgb(28, 28, 28);
}
.summaryPara{
  /* font-family: monospace; */
  color: rgb(74, 74, 74);
  letter-spacing: 0px;
  font-size: 19px;
}
.boxContent{
/* min-width: 60em;
max-width: 80em; */
  background-color: #fff;
  border-radius: 3%;
  width: 100%;
  border: 2px solid #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 22em;
}
.boxSumaryContent{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #d8d8d8;
  margin-top: -30px;
  height: 54px;
  padding-bottom: 6px;
}
.boxSumaryBelow{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d8d8d8;
  margin-top: -30px;
  height: 5em;
}
.sumarButton{
  margin-right: 7%;
  border-radius: 2em;
  background-color: rgb(77, 37, 255);
  color: #fff;
  width: 22%;
  height: 85%;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: medium;
}
.uploadContainerBtns{
  display: flex;
  padding-left: 7px;
  justify-content: space-around;
  width: 23%;
  padding-bottom: 6px;
}
.toggleSwitch {
  margin-left: 16px;
  margin-bottom: 10px;
  position: relative;
  width: 120%;
  height: 28px;
  background-color: #e6e6e6;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.option {
  width: 50%;
  text-align: center;
  z-index: 2;
  padding: 10px 0;
  color: #000;
  font-size: 14px;
}

.option.active {
  color: #fff;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color:  rgb(77, 37, 255);
  border-radius: 20px;
  transition: all 0.3s ease;
  z-index: 1;
}

.sliderRight {
  left: 50%;
}

.content {
  margin-top: 20px;
}

.sliderContainer {
  position: relative;
  width: 300px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 6em;
  margin-bottom: 5px;
}


.sliderLabelActive {
  color: #fff;
}

.sliderBar {
  position: absolute;
  top: 0;
  left: 4em;
  width: 40%;
  height: 100%;
  background-color: #4CAF50;
  border-radius: 20px;
  transition: all 0.3s ease;
  z-index: 1;
}

.sliderBarRight {
  left: 50%;
}
.labels{
  text-align: center;
  z-index: 2;
  padding: 10px 0;
  color: #727272;
  font-size: 14px;
  margin-right: 10%;
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.label1{
  margin-left: 20px;
}
.label{
  padding-left: 135px;
}


.uploadBox:hover {
  border-color: #7ebd81;
}

.fileLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.fileLabel span{
  font-size: 15px;
  font-weight: bold;
  color: #000;
}
.shareIcon{
  width: 25px;
  height: 22px;
  background: url('../../../assets/share-icon.svg') no-repeat center center;
  background-size: contain;
  stroke: #fff;
}
.downIcon{
  width: 25px;
  height: 22px;
  background: url('../../../assets/download-icon.svg') no-repeat center center;
  background-size: contain;
  stroke: #fff;
}
.pasteIcon{
  width: 25px;
  height: 22px;
  background: url('../../../assets/copy-icon.svg') no-repeat center center;
  background-size: contain;
  stroke: #fff;
}


.uploadIcon {
  width: 8em;
  height: 8em;
  background: url('../../../assets/upload-cloud-icon.svg') no-repeat center center;
  background-size: contain;

}
.anouncementBannerSection{
  width:100%;
  height: 1em;
  color: #0155ff;
}
.containerPaste{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding-top: 10px;
  border-radius: 100%;
  background-color: #81a0fe;
  cursor: pointer;
}
.textFile2{
  top: 62.1%;
  position: absolute; 
  font-size: 13px !important;
  color: #fff !important;
}
.textFile{
  top: 65.3%;
  position: absolute; 
  font-size: 13px !important;
  color: #fff !important;
}
.centeredText {
  text-align: center; /* Center the text within the container */
  width: 100%; /* Ensure the container takes full width */
}

.centeredText div {
  display: inline-block; /* Make each div behave like an inline element */
  text-align: center; /* Center the text within each div */
  width: 100%; /* Ensure each div takes full width */
}
.textFile3{
  top: 49%;
  position: absolute; 
  font-size: 13px !important;
  color: #fff !important;
}
.svgCloudBttom{
  width: 13em;
  height: 7em;
  position: absolute;
}
.pasteTextIcon{
  width: 20px;
  height: 18px;
  background: url('../../../assets/paste.svg') no-repeat center center;
  background-size: contain;
  margin-bottom: 10px;
}
.deleteIcon{
  margin-top: 4px;
  width: 30px;
  height: 25px;
  background: url('../../../assets/delete.svg') no-repeat center center;
  background-size: contain;
  cursor: pointer;
}
.wordsTextField{
  margin-top: 3px;
}

.fileInput {
  display: none;
}

span {
  color: #797979;
  font-weight: bold;
  font-size: 15px;
}
.bottomBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100.4%;
  height: 2em;
  position: absolute;
  bottom: -2px;
  gap: 1em;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background: linear-gradient(to right, #0155ff,#08adff,#45c1ff);
}
.subsBtns{
  display: flex;
  width: 100%;
  gap: 1%;
}
.btnIneer{
  background-color: #0155ff;
  color: #fff;
  padding: 4px 23px;
  border-radius: 3em;
  font-weight: bold;
}
.btnIneer2{
  background-color: #fff;
  border: 1px solid #0155ff;
  color: #0155ff;
  padding: 4px 23px;
  border-radius: 3em;
  font-weight: bold;
}
.subsBtnsContainer {
  position: relative;
  display: flex;
  width: 85%;
  padding: 2em;
  border-radius: 3em;
  background: #e0edff;
  z-index: 1;
}
.generateButton{
padding: 4px 23px;
background: linear-gradient(to right, #0155ff,#08adff,#45c1ff);
color: #fff;
border-radius: 3em;
font-weight: bold;
}
.textSummary{
font-weight: bold;
font-size: 20px;
margin-bottom: 1em;
color: #474747;
}

.subsBtnsContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 3px;
  background: linear-gradient(to right, #0155ff, #e0edff, #0155ff);
  -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
  mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: subtract;
  z-index: -1; /* Place it behind the content */
}
.uploadbox{
width: 50%;
display: flex;
align-items: center;
justify-content: center;
height: 40vh;
}

.contaoner{
  display: flex;
  gap:1rem;
  width: 100%;
}
.sourceContainer{
  background-color: #fff;
  width: 45%;
  height: 14em;
  
}
.sourceImg{
  height: 7em;
  width: 40%;
}
.sourceText{
  font-weight: 800;
  font-size: 20px;
  margin-top: 9px;
}
.sourceText2{
  font-weight: 900;
  margin-bottom: 12px;
  font-size: 27px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  top: 0;
  left:33%; */
  color: #083799;
}
.sourceText3{
  font-weight: 900;
  font-size: 27px;
  margin-bottom: 12px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  top: 0;
  left:23%; */
  color: #083799;
}