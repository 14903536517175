.comparisionHeading{
    font-size: 20px;
    color: rgb(0, 0, 60);
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4em;
}
.mainContainer{
    display: flex;
    margin-bottom: 5em;
}
.dashboardContainer{
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    width: 25vw;
    font-size: 18px;
    padding: 0 10px;
    color: #373737;
}
.disabled{
    pointer-events: none; /* Prevent clicks */
    opacity: 0.6;        /* Dim the button */
    cursor: not-allowed;
}
.dashboardHeading{
    width: 100%;
    height: 50px;
    color: #fff;
    background-color: rgb(0, 22, 133);
    display: flex;
    align-items: center;
    justify-content: center;
}
.dashboardfile{
    width: 100%;
    height: 50px;
    background-color: #efefef;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.dashboardfile2{
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dashboardanaly{
    width: 100%;
    height: 50px;
    background-color: #a3ccff;
    display: flex;
    color: #000;
    font-size: 17px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.analysisActive{
    color: #000;
    background-color: #64aaff;
}
.inputTopic{
    width: 14em;
    border-radius: 10em;
    padding: 4px;
    margin: 1em 0;
    border: #fff solid 1px;
    box-shadow: 2px 7px 2px rgba(0, 0, 0, 0.15);
}
.menuContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1em;
    background-color: #eaeaea;
    padding: 0 2em;
}
.menuitems{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
}
.menuitemsadd{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border: #fff solid 1px;
    width: 11em;
    padding: 4px 0;
    border-radius: 10em;
    /* box-shadow: 2px 7px 2px rgba(0, 0, 0, 0.15); */
}
.menuitemsadd:hover{
    
    background-color: #fff;
    cursor: default;
    /* box-shadow: 2px 7px 2px rgba(0, 0, 0, 0.15); */
}
.iconadd{
    margin-left: 7px;
    background: #ADD7F7;
    border-radius: 15px;
    padding: 3px;
    align-items: center;
    display: flex;
    justify-content: center;
}
.iconadd svg{
    width: 18px;
    height: 19px;
}
.menuitemsdelete{
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff7e7e;
    color: #fff;
    width: 10em;
    cursor: default;
    padding: 4px 0;
    border-radius: 10em;
    /* box-shadow: 2px 7px 2px rgba(0, 0, 0, 0.15); */
}
.menuitemsdelete:hover{
    background-color: #ff3737;
}
.textdelete{
    color: #ffffff;
}
.finalColumn{
    min-width: 17em;
    height: 5em;
    font-size: 14px;
    position: relative;
}
.fileanalaysisColumn{
    min-width: 17em;
    height: 5em;
    font-size: 14px;
    position: relative;
    
}
.halfContent{
    position: relative;
}
.halfContent2{
    position: relative;
}
.halfContent2 div{
    color: #00c93c;
}
.truncatedContent {
    display: inline;

}
.truncatedContent2 {
    display: inline;
    margin-left: 10px;
    cursor: pointer;

}
.moreIcon{
    position: absolute;
    right: 50%;
    bottom: 5%;
    cursor: pointer;
}
.openIcon{
    position: absolute;
    left: -3px;
    bottom: 3em;
    cursor: pointer;
    z-index: 2;
}
/* .moreIcon2{
    position: absolute;
    right: 1em;
    bottom:5%;
} */
.viewMoreIcon{
    position: absolute;
    right: -3px;
    top: -2px;
    cursor: pointer;
    z-index: 2;
}

.showMoreButton {
    cursor: pointer;
    color: #007bff;
    font-size: 14px;
    margin-left: 5px;
}

.fullContent {
    position: absolute;
    top: -74px;
    left: 0; 
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.fullContent2 {
    position: absolute;
    top: -74px;
    left: 0; 
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.truncatedContent:hover + .fullContent {
    display: block; /* Show full content on hover */
}
.truncatedContent2:hover + .fullContent2 {
    display: block; /* Show full content on hover */
}
.topicHead{
    color: #fff;
    font-weight: 300;
    background-color:  rgb(0, 22, 133);
    width: 100%;
    display: table-row;
    height: 4em;
}
.topicHead th{
    font-weight: 400;
    text-align: start;
    padding: 3px 0;
    padding-left: 2em;
}
.whiteRow {
    background-color: white;
}

.greyRow {
    height: 8em;
    background-color: #d7d7d7; /* Light gray */
}
.contradict{
    color: #ff0000;
    font-weight: bold;
    margin-bottom: 0;
}
.largeCheckbox {
    transform: scale(1.5);
}
.selectedFileDash{
    background-color: #a5bbff;
}
.loadingIcon{
    margin-left: 33%; 
}
.anaiconButton {
    background: #ADD7F7;
    border-radius: 8px;
    width: 7em;
    padding: 5px 0;
    padding-right: 15px;
    padding-left: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20%;
  }
.anaiconButton p{
    color: #5e5e5e;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

/* Animation for sliding down the new column */
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Class for the animated new column */
.animateColumn {
  animation: slideDown 0.4s ease-in-out;
}

/* Styling for the input field for entering the topic name */
.topicInput {
  width: 100%;
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Basic styling for table, rows, and cells */
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  /* min-width: 15rem; */
}

.topicColumn{
    min-width: 20em;
    font-size: 14px;
    height: 8em;
}
/* .topicSelect{
    max-width: 1rem !important;
    min-width: 1rem !important;
} */

.whiteRow {
  background-color: #ffffff;
  height: 8em;
}

.greyRow {
  background-color: #f9f9f9;
}

.largeCheckbox {
  width: 1.2em;
  height: 1.2em;
}
