
.headlineContainer {
    padding: 0 15em;
    padding-top: 5%;
    padding-bottom: 7em;
    /* padding-right: 7em; */
    display: flex;
    /* background-color: #ffffff; */
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background: linear-gradient(#ffffff,#ffffff,#f0f9ff ,#daebff);
}
.fileInput {
    display: none;
  }
.headlineSpace{
    display: flex;
    justify-content:space-between;
    align-items: center;
    /* width: 100%; */
}
.mainHome{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.summarContainer{
    padding-top: 6em;
    padding-bottom: 2em;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    background: linear-gradient(#ffffff,#fffbfb,#ebf7ff ,#daebff);

}
.partnersTage{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
    padding-bottom: 3%;
    
}
.partnersText{
    font-size:3em;
    font-weight: bolder;
    color: #353535;
}
.partnerContainer{
    position: absolute;
    bottom: -4em;
    height: 16vh;
    width: 80%;
    border: 1px dashed #c9c9c9;
    background-color: #fff;
    display: flex;
    gap: 2em;
    align-items: center;
    justify-content: center;
}
.eachContainer{
    width: 13.5%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dbdbdb;
    font-size: 25px;
    font-weight: 900;
    letter-spacing: 4px;
    -webkit-text-stroke: 1px black;
    stroke: 1px black;
}